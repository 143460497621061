@import '@/styles/mixins';
.container {
    display: flex;
    flex-direction: column;
    padding: 40px 20px 0 20px;
    @include screen('desktop') {
        padding-top: 56px;
    }
    .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.56px;
        color: $button-dark;
    }
}
