@import '@/styles/mixins';

.container {
    width: 100%;
    height: 116px;
    padding: 28px 20px;
    position: absolute;
    z-index: 100;
    @include screen('desktop') {
        height: 100%;
        width: 200px;
        position: relative;
        padding: 0;
    }
    @include screen('medium-desktop') {
        width: 400px;
    }
}
.autocomplete {
    z-index: 100;
    color: black !important;
}
