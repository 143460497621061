@import '@/styles/mixins';

.container {
    margin: 0 auto 20px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 600px;
    width: 100%;

    @include screen('desktop') {
        padding: 0;
        max-width: 100%;
        width: 100%;
    }
    .innerWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}
.tabGroup {
    padding: 24px 0 16px 0;
    display: flex;
    gap: 24px;
}
.selectedTab {
    color: $button-medium;
}
.unselectedTab {
    color: $button-light;
}
.line {
    background: $button-medium;
    height: 2px;
    align-self: stretch;
}
.title {
    padding: 24px 0 16px 0;
}
.gradient {
    height: 100px;
    position: relative;
    top: -70px;
    background: linear-gradient(180deg, rgba(235, 233, 232, 0) 0%, #ebe9e8 100%);
    z-index: 50;
}
.seeMoreContainer {
    // display: flex;
    // flex-direction: column;
    // width: 100%;
    // margin-top: 110px;
}
.seeMore {
    display: flex;
    max-width: 100%;
    justify-content: center;
    align-self: center;
    margin: 24px 20px 0;
    color: $button-medium;
}

.cardsWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include screen('desktop') {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .tripCard {
        width: 100%;
        @include screen('medium-desktop') {
            width: calc(50% - 12px);
        }
        @include screen('big-desktop') {
            max-width: 560px;
        }
    }
}
