@import '@/styles/mixins';
.top {
    padding-top: 88px;
    @include screen('desktop') {
        padding-top: 192px;
    }
}
.root {
    width: 100vw;
    display: flex;
    flex-direction: column;
}
.body {
    align-self: center;
}