@import '@/styles/mixins';

.root {
    position: relative;
    .inputWrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 11px 16px;
        background-color: $section-medium;
        border-radius: 12px;
        border-width: 0;
        gap: 10px;
        font-size: 16px;
        &.isFocus {
            outline: 1px solid $button-light;
        }
        &.error {
            outline: 1px solid #bd1b1b;
        }

        .input {
            font-size: 16px;
            height: 18.5px !important;
            width: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            &::placeholder {
                color: $button-inactive;
            }
        }

        .selectedCountry {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            background-color: rgba(117, 137, 116, 0.6);
            padding: 6px 8px;
        }
    }

    .dropdown {
        border-radius: 12px;
        border-width: 0;
        font-size: 16px;
        z-index: 2;
        position: absolute;
        padding: 16px 0;
        transform: translateY(100%);
        bottom: -15px;
        left: 0;
        right: 0;
        background-color: $section-medium;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
        outline: 1px solid $button-light;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .liSelect {
            padding: 10px 16px;
            display: flex;
            align-items: center;
            &.highlighted {
                background-color: #ebe9e8;
            }
            cursor: pointer;
        }
    }
}

.invalidInput {
    margin-top: 8px;
    margin: 4px 0 0 4px;
    font-size: 16px;
    color: $important;
}