@import '@/styles/mixins';
.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-wrap: wrap;
    background-color: $button-medium;
    color: $primary;
    padding: 64px 20px 24px 20px;
    max-width: 100%;
    @include screen('desktop') {
        padding: 44px 20px 44px 20px;
    }
}
.textrow {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    .title {
        font-family: $h4-font-family;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }
}
