@import '@/styles/mixins';
.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
}
.space {
    height: 56px;
}
.vidspace {
    //height: 200px;
}
.ctacontainer {
    background-color: $section-light;
    padding: 32px 20px;
    display: flex;
    justify-content: center;
    @include screen('desktop') {
        padding: 56px 20px;
    }
    .btn {
        width: 100%;
        @include screen('xsmall-tablet') {
            width: 400px;
        }
    }
}
.heading {
    display: flex;
    flex-direction: column;
    .element {
        width: 100%;
    }
    @include screen('desktop') {
        flex-direction: row;
        gap: 80px;
        .element {
            display: flex;
            align-items: center;
            width: 100%;
        }

        padding: 83px 0;
    }
}
