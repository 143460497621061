@import '@/styles/mixins';

.root {
    .menuWrap {
        display: none;
        @include screen('desktop') {
            display: block;
            height: 110px;
        }
    }

    .container {
        .titleWrapper {
            background-color: $button-medium;
            .title {
                padding: 40px 0;
                color: $primary;
            }
        }
    }
}