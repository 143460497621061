@import '@/styles/mixins';
.heading {
    display: flex;
    flex-direction: column;
    .element {
        width: 100%;
    }
    @include screen('desktop') {
        flex-direction: row;
        gap: 80px;
        .element {
            display: flex;
            align-items: center;
            width: 100%;
        }

        padding: 83px 0;
    }
}

.mobile {
    @include screen('desktop') {
        display: none !important;
    }
}
