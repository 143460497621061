@import '@/styles/mixins';

.root {
}
.row {
    padding: 6px 0;
    display: flex;
    align-items: center;
    h3 {
        margin-right: 15px;
        min-width: max-content;
    }
}

.modal {
    background-color: $section-medium;
}

.errorWrap {
    margin-top: 30px;

    .titleErr {
        margin-bottom: 16px;
    }
    .errorLi {
        cursor: pointer;
        padding: 20px 10px;
        border-radius: 20px;
        &:hover {
            background-color: #ebe9e8;
        }

        .step {
            font-size: 16px;
            font-weight: bold;
            margin-right: 8px;
        }
    }
}

.logout {
    cursor: pointer;
    margin-top: 30px;
    padding: 10px 20px;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    background-color: $section-medium;
}
