@import '@/styles/mixins';
.root {
    position: relative;
    height: 520px;
    @include screen('desktop') {
        min-height: 784px;
        height: 784px;
    }
}

.gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 520px;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0.6) 100%
    );

    @include screen('desktop') {
        height: 784px;
    }
    z-index: 2;
}

.container {
    height: 100%;
    width: 100%;
    .videoWrapper {
        position: absolute;
        height: 0;
    }

    video {
        object-fit: cover;
    }

    .controls {
        .iconcontainer {
            z-index: 3;
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 64px;
            height: 64px;
            border-radius: 200px;
            border: 1.5px solid $primary;
            background: rgba(251, 251, 251, 0.15);
            backdrop-filter: blur(2.5px);
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
                right: 18.747px;
                top: 18px;
            }
        }
    }
    .previewControls {
        top: 295px;
    }
    .editControls {
        top: 226px;
    }
    .overlay {
        opacity: 1;
        visibility: visible;
    }
    .overlay:not(.isPlaying):hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
}
.btnWrap {
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    z-index: 3;
}

.tagGroup,
.tagGroupEdit {
    position: absolute;
    bottom: 24px;
    left: 0;
    width: 100%;
    z-index: 3;
}

.imageWrapper {
    width: 100%;
    height: 520px;
    @include screen('desktop') {
        height: 784px;
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 520px;
        @include screen('desktop') {
            height: 784px;
        }
    }
}
