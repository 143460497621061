
.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
}
.form {
    align-self: center;
    padding-top: 8px;
    max-width: 100%;
}