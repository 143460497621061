@import '@/styles/_mixins';

.root {
    padding: 56px 0;
    background-color: $section-medium;
    color: $text-on-primary;
    .title {
        padding: 0 20px 32px;
    }
}
.preview {
    background-color: $section-light;
    color: $section-medium;
}