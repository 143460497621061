@import '@/styles/mixins';
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.loaderCircle {
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-radius: 50%;
    position: absolute;
    animation: rotate 1s linear infinite;
}
