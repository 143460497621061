@import '@/styles/mixins';
@import '@/styles/buttons';

.container {
    background-color: $section-medium;
    padding: 40px 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.row {
    display: flex;
    gap: 6px;
    width: 100%;
    max-width: 100%;
    align-items: center;
    padding: 0 20px 24px 20px;
    // @include screen('desktop') {
    //     padding: 0 24px 24px 24px;
    // }
}
.tagGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.tag {
    background-color: $primary;
    padding: 8px 12px;
    border-radius: 20px;
    color: $button-medium;
}
.preText {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 26px;
}
.line {
    height: 1px;
    background: $button-dark;
    opacity: 0.15;
    margin: 0 20px;
}
.btns {
    padding: 0 20px;
    display: none;
    gap: 16px;
    width: 100%;
    padding-top: 8px;
    @include screen('desktop') {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .button {
        flex-grow: 2;
        max-width: 50%;
    }
}
.share {
    flex-grow: 2;
    position: relative;
    .button {
        width: 100%;
        max-width: 100%;
    }
}

.preText {
    min-width: max-content;
}

.editWrap {
    padding-right: 24px;
    padding-bottom: 32px;
}
