@import '@/styles/mixins';

.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
    @include screen('desktop') {
        max-width: 350px;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .container {
        width: 56px;
        height: 56px;
        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            background: $primary;
            width: 56px;
            height: 56px;
        }
    }
    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        font-weight: 400;
        .point {
            font-weight: 700;
        }
    }
}
