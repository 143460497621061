@import '@/styles/forms';

.invalidLabel {
    color: $important;
}
.cont {
    max-width: 560px;
    margin: 0 auto;
    padding: 40px 20px 0 20px;
    @include screen('desktop') {
        padding-top: 56px;
    }
}
