@import '@/styles/variables';

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;

    .icon {
        align-self: center;
    }
    .h4 {
        font-family: $h4-font-family;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }
}

.iconcontainer {
    height: 56px;
    width: 56px;
    .icon {
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        border: 1.5px solid $button-light;
        background: $button-light;
    }
}
.root {
    margin: 0 auto;
    padding: 32px 20px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .link {
        display: block;
        padding-bottom: 64px;
    }
}
