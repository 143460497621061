@import '@/styles/iconSelect';
.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
    padding: 0 20px;
}
.button {
    padding: 0 20px 26px;
}
.title {
    flex-grow: 2;
}

.iconTitle {
    min-width: 100%;
}
