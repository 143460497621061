@import '@/styles/mixins';

.container {
    max-width: 600px;
    margin: 0 auto;
}
.noResults {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;
    align-self: center;
    padding: 32px 0;
    @include screen('xsmall-tablet') {
        max-width: calc(560px);
        padding: 56px 0;
    }
}
