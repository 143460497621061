@import '@/styles/_mixins';

.root {
    padding: 56px 20px;
    width: 100%;
    background-color: $text-on-primary;
    color: $primary;
    .wrap {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin: 0 auto;
    }

    .header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;
        .logo {
        }
        .socials {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            .instagram,
            .x {
                padding: 8px;
                border-radius: 20px;
                background-color: $button-light;
                i {
                    z-index: 100;
                    color: $primary;
                }
            }
        }
    }
    .menu {
        a {
            height: 48px;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: inherit;
        }
    }
    .divider {
        width: 100%;
        height: 1px;
        background-color: $button-dark;
    }
    .copyright {
    }
}
