@import '@/styles/mixins';

.head {
    margin-bottom: 32px;
}

.top {
    margin-top: 32px;
    @include screen('desktop') {
        margin-top: 0;
        // padding-top: 160px;
    }
}

.container {
    max-width: 100%;
    overflow-x: hidden;
}
.title {
    padding-left: 20px;
    padding-right: 20px;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    max-width: 100%;
    overflow-x: hidden;
}
.title {
    padding-left: 20px;
    padding-right: 20px;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal {
    background-color: $section-medium;
}

.errorWrap {
    margin-top: 30px;

    .titleErr {
        margin-bottom: 16px;
    }
    .errorLi {
        cursor: pointer;
        padding: 20px 10px;
        border-radius: 20px;
        &:hover {
            background-color: #ebe9e8;
        }

        .step {
            font-size: 16px;
            font-weight: bold;
            margin-right: 8px;
        }
    }
}

.submit {
    width: 100%;
    margin: 0 auto 32px;
    max-width: 600px;
}

.root {
    overflow: hidden;
}

.heading {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-direction: column;
    @include screen('desktop') {
        flex-direction: row;
        padding: 40px 0;
    }
    gap: 26px;
    .element {
        width: 100%;
        max-width: 100%;
        @include screen('desktop') {
            max-width: 50%;
        }
    }
    @include screen('desktop') {
        margin: 0 auto;
        padding: 72px 0px;
    }
}
