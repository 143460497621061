@import '@/styles/iconSelect';
.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;

}
.tag {
    color: $button-light;
    padding-bottom: 2px;
}
