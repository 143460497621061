@import '@/styles/mixins';

.h1,
.h2,
.h3 {
    font-weight: 700;
}
.h1 {
    font-size: 48px;
    line-height: 53px;
    letter-spacing: 0.96px;
    @include screen('xsmall-tablet') {
        font-size: 64px;
        font-weight: 700;
        line-height: 70px;
        letter-spacing: 1.28px;
    }

    @include screen('desktop') {
        font-size: 96px;
        line-height: 110px;
        letter-spacing: 1.92px;
    }
}
.h2 {
    font-size: 38px;
    line-height: 46px;
    letter-spacing: 0.76px;
}
.h3 {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.56px;
    @include screen('desktop') {
    font-size: 32px;
    letter-spacing: 0.64px;
    }
}
.h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    @include screen('tablet') {
        font-weight: 700;
    }
}
.p,
.p_strong,
.p_link {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    @include screen('desktop') {
        font-size: 18px;
    }
}
.p_strong {
    font-weight: 700;
}
.p_link {
    font-weight: 700;
    text-decoration-line: underline;
    cursor: pointer;
}
.small,
.small_strong,
.small_link {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.small_strong {
    font-weight: 700;
}
.small_link {
    font-weight: 700;
    text-decoration-line: underline;
    color: inherit;
    cursor: pointer;
}
.button {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.64px;
}
.tag,
.form {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
}
.form {
    font-weight: 400;
}
