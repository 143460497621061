@import '@/styles/mixins';
.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.head {
    width: 100%;
    @include screen('desktop') {
        padding-bottom: 32px;
    }
}