@import '@/styles/variables';
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;
    .icon {
        align-self: center;
    }
}

.iconcontainer {
    height: 56px;
    width: 56px;
    .icon {
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        border: 1.5px solid $important;
        background: $important;
    }
}
.root {
    margin: 0 auto;
    padding: 32px 20px 0 20px;
    max-width: 560px;
    .desc {
        display: inline-block;
    }
    .link {
        padding-bottom: 64px;
    }
}
