@import '@/styles/variables';

.container {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.info {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;
}
.imgcontainer {
    position: relative;

    display: flex;
    width: 99.84px;
    height: 96px;
    .image {
        border-radius: 99.84px;
        border: 2px solid $primary;
        object-fit: cover;
        width: 99.84px;
        max-width: 100px;
    }
}
.smallImgcontainer {
    position: relative;
    width: 64px;
    height: 64px;
    .image {
        width: 64px;
        height: 64px;
        object-fit: cover;
    }
}
.icons {
    display: flex;
    gap: 32px;
    color: $primary;
}
.updateButton {
    color: $primary;

    &:visited {
        color: $primary;
    }
}

.link {
    color: $primary;
    &:visited {
        color: $primary;
    }
}
