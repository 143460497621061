@import '@/styles/mixins';
@import '@/styles/buttons';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    justify-content: center;
    background-color: $section-medium;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 40px;
    position: sticky;
    bottom: 0;
    z-index: 100;

    .share {
        position: relative;
    }
    .button {
        width: 167px;
        max-width: 100%;
        @include screen('tablet') {
            width: 352px;
        }
    }
    .popup {
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 6px;
    }
    @include screen('small-mobile') {
        flex-direction: row;
    }
}
