@import '@/styles/variables';
.container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    border-radius: 20px;
    background: rgba(251, 251, 251, 0.15);
    backdrop-filter: blur(2.5px);
    padding: 8px 16px;
    color: $primary;
    -webkit-tap-highlight-color: rgba(117, 137, 116, 0);
    font-size: 14px !important;

    p,
    i {
        font-size: 14px !important;
    }
}
