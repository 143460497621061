@import '@/styles/_mixins';

.root {
    z-index: 999;
    position: fixed;
    bottom: 40px;
    left: 20px;
    right: 20px;
    color: $text-on-primary;
    background-color: $primary;
    border-radius: 16px;
    padding: 32px 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12);

    @include screen('tablet') {
        width: 664px;
        margin: auto;
        bottom: 40px;
    }
    @include screen('desktop') {
        padding: 32px;
        background-color: $section-medium;
        width: 1200px;
        max-width: calc(100% - 160px);
    }
}

.wrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    @include screen('desktop') {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.manageCookies {
    cursor: pointer;
    color: $button-medium;

    &:hover {
        i {
            transform: translateX(4px);
        }
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 100%;

    @include screen('tablet') {
        width: 448px;
    }
    @include screen('desktop') {
        flex-direction: row-reverse;
        button {
            min-width: 310px;
            padding: 10px 16px;
        }
    }
}
