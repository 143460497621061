@import '@/styles/variables';

.container {
    padding: 56px 0px;
    display: flex;
    flex-direction: column;
    .title {
        padding-bottom: 32px;
    }
}
.preview {
    color: $button-inactive;
}
