@import '@/styles/buttons';

.root {
    margin: 32px auto 60px;
    padding: 0 20px;
    max-width: 560px;

    .primary {
        margin-top: 32px;
    }
}
