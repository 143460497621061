@import '@/styles/mixins';
.root {
    width: 100vw;
    display: flex;
    flex-direction: column;
}
.menu {
    display: none;
    @include screen('desktop') {
        display: inline;
    }
}
.container {
    @include screen('desktop') {
        padding-top: 110px;
    }
}