@import '@/styles/mixins';

.container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 116px;
    padding-bottom: 56px;

    @include screen('desktop') {
        padding-top: 185px;
        padding-bottom: 120px;
    }
}
