@import '@/styles/variables';

.row {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 12px;

    border-bottom: 1px solid $section-medium;
    svg {
        height: 24px;
        width: 24px;
    }
    .icon {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $button-medium;
    }
    .optional {
        color: $button-light;
    }
    .point {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .desc {
            margin-left: -36px;
        }
    }
}
.preview {
    border-bottom: 1px solid $button-inactive;
}
.previewIcon {
    color: $button-inactive;
}
