@import '@/styles/mixins';
.heading {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 0px;
    @include screen('desktop') {
        gap: 80px;
        align-items: stretch;
        flex-direction: row;
        margin: 0 auto;
        padding: 72px 0px;
    }
    .element {
        width: 100%;
        max-width: 100%;
        @include screen('desktop') {
            max-width: 50%;
        }
    }
}
