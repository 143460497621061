@import '@/styles/mixins';

.root {
    background-color: $section-medium;
    color: $button-dark;
    &.preview {
        background-color: $section-light;
        color: $button-inactive;
    }
    .content,
    .heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
    .content {
        @include screen('desktop') {
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    .heading {
        padding-bottom: 32px;
    }
}
