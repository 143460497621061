@import '@/styles/mixins';
@import '@/styles/forms';
@import '@/styles/uploadButton';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    // padding-bottom: 26px;
    // @include screen('desktop') {
    //     width: 560px;
    //     padding-bottom: 56px;
    // }
}
.imageWrap {
    overflow: hidden;
    position: relative;
    height: 250px;
    width: 100%;
    border-radius: 16px;
    display: block;
    img {
        object-fit: cover;
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: calc(100% - 3px);
    height: 250px;
    border-radius: 12px;
    border: 2px dashed var(--Green-300, $button-light);
    background-color: white;
    cursor: pointer;
}

.btnGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
    padding: 32px 0px;
    width: 100%;
    max-width: 400px;

    .loaderBtn {
        display: flex;
        height: 48px;
        max-width: 560px;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: 12px;
        text-decoration: none;
        cursor: pointer;
        background-color: #455d54;
        color: #ebe9e8;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0.64px;
    }
    @include screen('desktop') {
        padding: 56px 20px;
    }
}
.accTitle {
    padding-top: 24px;
    margin-bottom: 16px;
}
.stopTitle {
    margin-bottom: 16px;
}
