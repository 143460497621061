@import '@/styles/mixins';

.container {
    width: 100%;
    .button {
        background-color: $button-medium;
        color: $primary;
        &.medium {
            max-width: 400px;
            margin: 0 auto;
            @include screen('mobile') {
                max-width: 100%;
                width: 100%;
            }
        }
        &.light {
            background-color: $primary;
            color: $button-medium;
            border: 2px solid $button-medium;
        }

        cursor: pointer;
        display: flex;
        height: 48px;
        width: 100%;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: 12px;
        text-decoration: none;
    }
    .disabled {
        background-color: $button-inactive;
    }
}
