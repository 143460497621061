@import '@/styles/mixins';
.opaqueroot,
.root,
.reverseOrder {
    display: flex;
    width: 100vw;
    max-width: 100%;
    padding: 28px 20px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 50;
    background-color: $primary;
    color: $text-on-primary;
    .logo {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        max-width: 100%;
        height: 60px;
        background-color: transparent;
    }
}
.reverseOrder {
    flex-direction: row-reverse;
}
.iconContainer {
    width: 24px;
    height: 24px;
    cursor: pointer;
    .icon {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.menuContainer,
.profileMenuContainer {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(29, 37, 37, 0.92);
    backdrop-filter: blur(10px);
    transition: left 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 51;
}
.menuContainer {
    left: -100vw;
}
.profileMenuContainer {
    right: -100vw;
}
.openMenuContainer {
    left: 0px;
    transition: 0.4s;
    color: $primary;
}
.openProfileMenuContainer {
    right: 0px;
    transition: 0.4s;
    color: $primary;
}
.animateMenuClose {
    left: -100vw;
    transition: 0.4s;
}
.animateProfileMenuClose {
    right: -100vw;
    transition: 0.4s;
}
.menuItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 32px;
    padding-right: 32px;

    hr {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        opacity: 0.2;
    }
}
.menuItem {
    height: 48px;
    align-content: center;
}
.menuCloseRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 56px 32px;
}
.accountSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 32px;
}
.iconrow {
    display: flex;
    gap: 8px;
    align-items: center;
    .iconcontainer {
        height: 20px;
        width: 20px;
        .icon {
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.logo {
    width: 76.002px;
    height: 24.001px;
}
.desktopcontainer,
.desktopOpaqueContainer {
    padding: 6px 80px 0;
    width: 100%;
    background-color: $primary;
    color: $text-on-primary;
    z-index: 3;
    position: absolute;
    .rowWrap {
        .row {
            max-width: 1520px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 32px 0;
        }
        border-bottom: 1px solid rgba($primary, 0.3);
    }
    .rightside {
        display: flex;
        gap: 88px;
        height: 40px;
        align-items: center;
        .search {
            display: flex;
            align-items: center;
            gap: 40px;
        }
    }
}
.desktopcontainer {
    flex-grow: 2;
    .rowWrap {
        border-bottom: 1px solid rgba($text-on-primary, 0.3);
    }
    .search {
        .verticleline {
            height: 40px;
            border: 1px solid rgba($text-on-primary, 0.3);
        }
    }
}
.opaqueroot,
.desktopOpaqueContainer {
    background-color: transparent;
    color: $primary;
    .rowWrap {
        border-bottom: 1px solid rgba($primary, 0.3);
    }
    .search {
        .verticleline {
            height: 40px;
            border: 1px solid rgba($primary, 0.3);
        }
    }
}

.desktop {
    min-width: 100%;
    display: none;
    @include screen('desktop') {
        display: block;
    }
}
.mobile {
    display: block;
    @include screen('desktop') {
        display: none;
    }
}
