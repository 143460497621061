@import '@/styles/mixins';
@import '@/styles/buttons';

.btns {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0 40px 0;
    max-width: 800px;
    width: 100%;
}
.footer {
    margin: 0 auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
}
.titleWrap {
    width: 100%;
    text-align: start;

    .title {
        @include screen('desktop') {
            color: $primary;
        }
    }
}

.hr {
    padding: 32px 20px 32px 20px;
    .line {
        height: 1px;
        background: $button-medium;
        opacity: 0.15;
    }
}

.termsOfUse {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    max-width: 100%;
    padding: 0px 20px 61px 20px;
    color: $button-medium;
    @include screen('desktop') {
        color: $primary;
    }
}
.divider {
    padding: 0 4px;
}
