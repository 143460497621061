@import '@/styles/mixins';
.container {
    display: flex;
    flex-direction: column;
    min-width: min-content;
    width: 100%;
    max-width: 100% !important;
    @include screen('xsmall-tablet') {
        width: 560px;
    }
}
.card {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
}

.imageWrap {
    position: relative;
    height: 200px;
    width: 100%;

    @include screen('desktop') {
        height: 240px;
    }
    .image {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        cursor: pointer;
        object-fit: cover;
        width: 100%;
        max-width: 100%;
    }
}

.titleOverlay {
    background-color: $button-medium;
    color: $primary;
    padding: 16px 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .titlerow {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title {
        padding-top: 8px;
    }
    .iconcontainer {
        height: 24px;
        width: 24px;
        .icon {
            height: 24px;
            width: 24px;
        }
    }
}
.buttonsrow {
    display: flex;
    gap: 16px;
    padding-top: 16px;
}
.button {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
}
