@import '@/styles/mixins';
.menu {
    display: none;

    @include screen('medium-desktop') {
        display: block;
    }
}

.root {
    margin-top: 0;
    padding-bottom: 40px;

    @include screen('medium-desktop') {
        margin-top: 110px;
        padding-bottom: 56px;
    }
    .titleWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0;

        i {
            cursor: pointer;
            font-size: 24px;
        }

        @include screen('desktop') {
            padding: 56px 0 40px;
        }
    }

    .container {
        margin: 0 auto;
        max-width: 1200px;
    }
    .contentWrap {
        display: flex;
        justify-content: stretch;
        flex-wrap: wrap;
        gap: 24px;
        @include screen('desktop') {
            gap: 40px;
        }

        .item {
            max-width: 100%;
            width: 100%;

            @include screen('tablet') {
                padding-bottom: 24px;
                max-width: calc(50% - 20px);
            }
            .title {
                margin: 24px 0 6px;
                font-weight: 700;
                line-height: 36px;
                font-size: 28px;
            }
            .imageWrap {
                height: 300px;
                width: 100%;
                position: relative;
                border-radius: 12px;
                overflow: hidden;
                img {
                    object-fit: cover;
                }
            }
        }
    }
}

.desc {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}
.location {
    color: #455d54;
}
