.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100vw;
    gap: 8px;
    padding-left: 20px;
    padding-right: 20px;
}
