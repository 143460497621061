@import '@/styles/variables';
@import '@/styles/forms';

.heading {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
}
.addButton {
    border: 1.5px solid $button-inactive;
    color: $button-inactive;
    border-radius: 12px;
    display: flex;
    max-width: 400px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: center;
}
.addActive {
    border: 1.5px solid $button-medium;
    color: $button-medium;
}
.title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    color: $button-dark;
    .remove {
        color: $button-medium;
    }
}
.selectLabel {
    padding-top: 24px;
    padding-bottom: 8px;
    color: $button-medium;
}
.helperText {
    padding-bottom: 16px;
}

.submitButton {
    align-self: center;
}
