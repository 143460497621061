@import '@/styles/variables';
.root {
    a {
        text-decoration: none;
        color: inherit;
    }
}
.clientSection {
    background-color: $button-light;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
}
.tag {
    color: $button-dark;
}
.name,
.location {
    color: $primary !important;
}
.label {
    color: $button-light;
}
.link {
    color: $button-medium;
}
.detailsSection {
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
}
.rowText {
    display: flex;
    gap: 6px;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.iconcontainer {
    height: 24px;
    width: 24px;
    cursor: pointer;
    .icon {
        height: 24px;
        width: 24px;
        color: $button-medium;
    }
}
.line {
    height: 1px;
    background: $button-medium;
    opacity: 0.15;
}

.checkbox_wrapper {
    display: flex;
    padding: 16px 20px;
    gap: 10px;
    align-items: center;

    .checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
}
