@import '@/styles/variables';
.root {
    padding-bottom: 16px;
    .logo {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        max-width: 100%;
        height: 60px;
    }
    .termsOfUse {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        max-width: 100%;
        padding: 40px 20px 20px 20px;
        color: $button-medium;
    }
    .divider {
        padding: 0 4px;
    }
}
