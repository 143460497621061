@import '@/styles/mixins';

.root {
    background-color: $button-light;
    &.preview {
        background-color: $section-medium;
    }
}
.ctaSection {
    margin: 0 auto;
    color: $primary;
    padding-top: 56px;
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.cta {
    padding-top: 16px;
    width: 100%;
    max-width: 400px;
    align-self: center;
}
