@import '@/styles/mixins';
.root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 12px;

    .tag {
        color: $button-light;
    }
    .row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .icon {
            align-self: center;
        }
    }
    .modal {
        justify-content: space-between;
    }
    .h3 {
        font-family: $heading-font-family;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.56px;
        color: $button-dark;
    }
    .h4 {
        font-family: $body-font-family;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }
}
