@import '@/styles/mixins';

.heroWrap {
    position: relative;
    .titleWrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        margin: 0 auto;

        .title {
            z-index: 2;
            color: $primary;
            padding-top: 24px;
            padding-top: 24px;
            @include screen('xsmall-tablet') {
                width: max-content;
            }
        }
    }

    .beach {
        width: 100vw;
        height: 844px;
        object-fit: cover;
        @include screen('xsmall-tablet') {
            height: 844px;
        }
    }
}
.sectionWhy,
.sectionWho {
    padding-top: 56px;
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.why,
.who {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;

    @include screen('desktop') {
        max-width: 800px;
    }
    @include screen('medium-desktop') {
        max-width: 50%;
    }
}

.who {
    .action {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .btnRight {
            display: block;
            width: 100%;
            @include screen('desktop') {
                max-width: 400px;
            }
        }
    }
}
.greenBackground {
    background-color: $section-medium;
}
.sectionWho {
    @include screen('desktop') {
        align-items: flex-end;
    }
}

.btnLeft {
    @include screen('desktop') {
        width: 400px;
    }
}
