@import '@/styles/iconSelect';
.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
    height: 100%;
}
.iconTitle {
    align-items: center;
}
