.container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100vh - 180px);
}
.title {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
