@import '@/styles/mixins';
.root {
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 40px 20px;
    width: 100%;
    text-align: center;

    .editWrap {
        padding-right: 24px;
        padding-bottom: 32px;
    }

    @include screen('desktop') {
        padding: 32px 0px;
        text-align: left;
        padding-left: 20px;
        span {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
        }
        svg {
            font-size: 22px;
        }
    }
    @include screen('medium-desktop') {
        padding-left: 0px;
    }
    .pretitle {
        margin-bottom: 16px;
        @include screen('desktop') {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
        }
    }
    .title {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 53px;
        letter-spacing: 0.96px;
        @include screen('desktop') {
            font-size: 64px;
            font-weight: 700;
            line-height: 80px;
            letter-spacing: 1.28px;
        }
    }
    .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;
        padding: 32px 0px;
        color: $button-medium;

        @include screen('desktop') {
            justify-content: flex-start;
        }
        .loctag {
            display: flex;
            gap: 6px;
        }
        .daystag {
            display: flex;
            gap: 8px;
        }
    }
}

.desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}
