@import '@/styles/mixins';
.root {
    margin: 55px auto;
    .titleWrap,
    .actions {
        display: flex;
        align-items: center;
    }

    .description {
        margin: 16px 0 35px;
    }

    .iconContainer {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background-color: rgba(117, 137, 116, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
    }

    .actions {
        gap: 16px;
    }
}

.shareButtonWrap {
    width: 100%;

    .shareButon {
        width: 100%;
    }
}
