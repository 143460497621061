@import '@/styles/variables';
.root {
    background-color: $button-light;
    &.preview {
        background-color: $section-medium;
    }
}
.container {
    color: $primary;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
    align-items: flex-start;
    gap: 32px;
    .imgcontainer,
    .imgPreview {
        display: flex;
        width: 99.84px;
        height: 96px;
        .image {
            border-radius: 99.84px;
            border: 2px solid $primary;
            object-fit: cover;
            width: 100%;
            max-width: 100px;
        }
    }
    .imgPreview {
        .image {
            opacity: 0.3;
        }
    }
    .row {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        .column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            .btn {
                display: flex;
                padding: 6px 12px;
                align-items: center;
                background-color: $button-medium;
                max-width: fit-content;
                border-radius: 8px;
            }
            .previewbtn {
                background-color: rgba(69, 93, 84, 0.4);
            }
        }
    }
}
